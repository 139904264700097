/* eslint-disable no-undef */
import React from "react"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import Start from "@components/pageFunctionalities/pageAlternativaWhatsapp/components/start"
import CardsImg from "@components/pageTicker/pageSolucionOmnicanal/components/cardImgTwo/cardImgTwo"
import Integrations from "@components/pageTicker/integrations/integrations"
import { TitleUnderlineTestimonyOne, TitleUnderlineTestimonyTwo } from "@components/pageChannels/pageChatbots/titleUnderline/titleUnderlineTestimony"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import concesionarias from "@components/sharedComponents/testimonies/images/iconos/concesionarias.svg"
import salud from "@components/sharedComponents/testimonies/images/iconos/salud.svg"
import logistica from "@components/sharedComponents/testimonies/images/iconos/logistica.svg"
import gobierno from "@components/sharedComponents/testimonies/images/iconos/gobierno.svg"
import UseScore from "@components/pageCasosdeUso/pageAlternativaMarketing/components/useCards"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import data from "@components/pageFunctionalities/pageChatbotsEmpresas/data"

const StructurePageMarketing = ({ location }) => {
  const iconsSilder = i => {
    switch (i) {
      case 0:
        return finanzas

      case 1:
        return concesionarias

      case 2:
        return salud
      case 3:
        return logistica
      case 4:
        return gobierno
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Finanzas"
      case 1:
        return "Concesionarias"
      case 2:
        return "Salud"
      case 3:
        return "Logística"
      case 4:
        return "Gobierno"
      default:
        return "Conoce otros casos de éxito"
    }
  }
  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  const titleTestimony = <p>
  Ayudamos a diferentes <TitleUnderlineTestimonyTwo underline="diferentes" /> <TitleUnderlineTestimonyOne underline="sectores" /> a fortalecer la relación con sus clientes con respuestas automatizadas

</p>

  return (
    <>
      <div className="fnd">
        <BannerCookies />
        <div className="banner-invisible-ebook" />
        <div>
          <Header
            path="/productos/"
            location={location}
            bannerCTA={true}
            windowsWidth={windowsWidth}
          />
        </div>    
        <div className="container">
          <Start data={data.start} location={location} />
          <CardsImg data={data.cards} location={location} dataText={data.cardsText} />
          <Integrations location={location} />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
          <UseScore data={data.useScore} />
          <BannerContact data={data.contacts} location={location} link={true} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </div>
    </>
  )
}

export default StructurePageMarketing
