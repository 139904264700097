import React, { useEffect, useRef } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import useNearScreen from "@hooks/useNearScreen"


const CardsImg = ({ data, dataText }) => {

  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 14000,
  }
  

  return (
    <div className="container-image-cardTwo-pages-A-B" ref={fromRef}>
    <section className="container-cards-imagen--pages-relativeTwo container-cards-imagen--pages-relative" style={{position: "relative", zIndex: 1}}>
      <h2 className="title-question-pages-relative">
       {dataText.title}
      </h2>
      <p className="text-video-support">
      {dataText.description}
      </p>
      <section className="cards-imagen-pages-relative">
        {data.map((ele, i) => {
          return (
            <img
              key={i}
              src={ele}
              alt=""
              className="cards-imagen-pages-relative-img"
            />
          )
        })}
      </section>
      <section className="image-help-customermobile">
      <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((ele, i) => {
            return (
              <img
                key={i}
                src={ele}
                alt=""
                className="cards-imagen-pages-relative-img"
              />
            )
          })}
        </Slider>
      </section>
    </section>
    </div>
  )
}

export default CardsImg
